<template>
  <div class="sidebar">
    <Menu :default-active="activeRoute" router background-color="#fff" active-text-color="#38C3A1">
      <div class="sidebar-item" v-for="item in sidebarData" :key="item.path">
        <template v-if="!item.children">
          <MenuItem :index="item.path">
            <!-- <i :class="`mg-r-10 iconfont ` + item.icon"></i> -->
            <svg class="mg-r-10 fz-20 icon" aria-hidden="true">
              <use :xlink:href="`#` + item.icon"></use>
            </svg>
            <span slot="title">{{ item.name }}</span>
          </MenuItem>
        </template>

        <Submenu v-else :index="item.path">
          <template slot="title">
            <div class="flex items-center font-bold">
              <svg v-if="item.icon" class="mg-r-10 fz-20 icon" aria-hidden="true">
                <use :xlink:href="`#` + item.icon"></use>
              </svg>
              <p>{{ item.name }}</p>
            </div>
          </template>

          <MenuItem v-for="item in item.children" :key="item.path" :index="item.path" :disabled="isDisabled(item.name)">
            <span slot="title">{{ item.name }}</span>
          </MenuItem>
        </Submenu>
      </div>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuItem, Submenu } from 'element-ui';

export default {
  name: 'sidebar',

  components: {
    MenuItem,
    Menu,
    Submenu,
  },

  props: {
    rootPath: {
      type: String,
      default: '/back-stage',
    },

    withRoot: {
      type: Boolean,
      default: false,
    },

    // 跳转路由，带上一个路由的query参数
    withQuery: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sidebarData: [],
    };
  },

  computed: {
    activeRoute() {
      const { path, meta, matched } = this.$route;
      let route = meta.inherit ? matched[matched.length - 1].parent.path : path;

      if (this.withQuery) {
        route += `?${window.location.href.split('?')[1]}`;
      }

      return route;
    },

    userInfo() {
      return this.$store.getters.userInfo;
    },
  },

  methods: {
    getSidebarData() {
      let backStageRoutes = [...this.$store.state.route.routes.find((x) => x.path === this.rootPath).children];
      this.sidebarData = this.getChildren(backStageRoutes, this.rootPath);

      // 是否包含根节点，主要为了得到嵌套关系
      if (this.withRoot) {
        const root = this.$store.state.route.routes.find((x) => x.path === this.rootPath);

        this.sidebarData = [
          {
            path: root.path,
            name: root.meta.title || '',
            icon: root.meta.icon || '',
            children: this.sidebarData,
          },
        ];
      }
    },

    getChildren(routes, path) {
      let children = [];

      routes.forEach((route) => {
        if (route.meta.hidden) return;

        let routePath = `${path}/${route.path}`;
        let child = {
          path: routePath,
          name: route.meta.title || '',
          icon: route.meta.icon || '',
        };

        if (this.withQuery && Object.keys(this.$route.query).length > 0) {
          child.path += `?${window.location.href.split('?')[1]}`;
        }

        if (route.children) {
          child.children = this.getChildren(route.children, routePath);
        }

        children.push(child);
      });

      return children;
    },

    // 菜单是否被禁用
    isDisabled() {
      if (this.userInfo.thirdpartySchoolProjectCode !== '') {
        // const blackList = ['升年级'];
        // return blackList.includes(menuName);
      }
      return false;
    },
  },

  created() {
    this.getSidebarData();
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  @apply bg-A10;
  height: 100%;
  width: 240px;
  flex-shrink: 0;
  padding-top: 16px;
  overflow: auto;

  ::v-deep .el-menu {
    border-right: none;
  }

  ::v-deep .el-menu-item {
    height: 36px;
    margin: 0 20px;
    display: flex;
    align-items: center;
    padding-left: 30px !important;
    border-radius: 8px;
    background-color: #fff !important;

    &.is-active,
    &:hover {
      color: $themeColor !important;
      background-color: #effffb !important;

      svg {
        fill: $themeColor;
      }
    }
  }
}

.sidebar-item {
  ::v-deep .el-submenu {
    .el-submenu__title {
      height: 48px;
      line-height: 48px;

      &.is-active,
      &:hover {
        color: $themeColor !important;
        //background-color: #f1fffc !important;
        background-color: transparent !important;
      }
    }
  }
}
</style>
